<template>
  <div :class="classes">
    <slot />
  </div>
</template>

<script>
export default {
  props: {
    grouped: {
      type: Boolean,
      default: false,
    },
    inline: {
      type: Boolean,
      default: false,
    },
    equalWidth: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    classes() {
      return {
        fields: true,
        grouped: this.grouped,
        inline: this.inline,
        equal: this.equalWidth,
        width: this.equalWidth,
      }
    },
  },
}
</script>
