<template>
  <container>
    <page-header>
      <grid>
        <grid-column six>
          <h1>People</h1>
        </grid-column>
        <grid-column ten right-aligned middle-aligned>
          <btn
            class="btn"
            basic
            variant="default"
            @click.native="$router.push({ name: 'people_import' })"
          >
            Import People
          </btn>
          <btn class="btn" @click.native="$refs.modal.show()">
            + Add Person
          </btn>
        </grid-column>
      </grid>
    </page-header>
    <tab-list pointing>
      <tab fragment="onboarding" :active="$route.hash === '#onboarding'">
        Onboarding
      </tab>
      <tab fragment="active" :active="$route.hash === '#active'"> Active </tab>
      <tab fragment="inactive" :active="$route.hash === '#inactive'">
        Alumni
      </tab>
    </tab-list>
    <loading v-if="pageLoading || formLoading" loading />
    <template v-else>
      <list middle-aligned divided selection size="large">
        <list-item
          v-for="user in users"
          :key="user.id"
          :src="user.avatar || defaultUserAvatar"
          :to="{ name: 'profile', params: { id: user.id } }"
        >
          <list-content>{{ user.fullName }}</list-content>

          <list-content
            v-if="$route.hash === '#onboarding'"
            class="grey formatted-date"
            float-right
          >
            Start date:
            {{
              user.startDate
                ? formatDate(parseISO(user.startDate), true)
                : 'not found'
            }}
          </list-content>

          <list-content
            v-if="$route.hash === '#inactive'"
            class="grey formatted-date"
            float-right
          >
            End date:
            {{
              user.endDate
                ? formatDate(parseISO(user.endDate), true)
                : 'not found'
            }}
          </list-content>
        </list-item>

        <placeholder
          v-if="users.length === 0"
          centered
          vertically-aligned
          light
        >
          {{ placeholderText }}
        </placeholder>
      </list>
      <pagination
        v-if="usersPagination && usersPagination.lastPage > 1"
        :num-of-pages="usersPagination.lastPage"
      />
    </template>
    <modal ref="modal" size="tiny" @deny="reset">
      <modal-header> Add Person </modal-header>
      <modal-content>
        <g-form id="create-user-form" @submit="createUser">
          <template #default="{ valid }">
            <form-validation
              :valid="valid"
              @update="(value) => (isFormValid = value)"
            />
            <form-field-group equal-width>
              <form-field>
                <form-label> First Name </form-label>
                <form-input
                  name="First Name"
                  :rules="['required', 'max:255']"
                  :initial-value="newUser.preferredFirstName"
                  placeholder="Hermione"
                  @update="(value) => (newUser.preferredFirstName = value)"
                />
              </form-field>
              <form-field>
                <form-label> Last Name </form-label>
                <form-input
                  name="Last Name"
                  :rules="['max:255']"
                  :initial-value="newUser.lastName"
                  placeholder="Granger"
                  @update="(value) => (newUser.lastName = value)"
                />
              </form-field>
            </form-field-group>
            <form-field>
              <form-label> Work Email </form-label>
              <form-input
                name="Work Email"
                :rules="['email', 'required', 'max:255']"
                :initial-value="newUser.email"
                placeholder="hermione.granger@ministryofmagic.gov"
                @update="(value) => (newUser.email = value)"
              />
            </form-field>
            <form-field>
              <form-label> Personal Email </form-label>
              <form-input
                name="Personal Email"
                :rules="['email', 'max:255']"
                :initial-value="newUser.personalEmail"
                placeholder="h.granger@owl.wiz"
                @update="(value) => (newUser.personalEmail = value)"
              />
            </form-field>
          </template>
        </g-form>
      </modal-content>
      <modal-actions space-between>
        <form-btn variant="default" type="reset" form-id="create-user-form">
          Cancel
        </form-btn>

        <form-btn
          variant="primary"
          type="submit"
          :invalid="!isFormValid"
          form-id="create-user-form"
        >
          Continue to Add Details
        </form-btn>
      </modal-actions>
    </modal>
  </container>
</template>

<script>
import { parseISO } from 'date-fns'
import { toLower } from 'lodash'
import { api } from '@/api'
import list from '@/components/v2/list/list.vue'
import listItem from '@/components/v2/list/item.vue'
import listContent from '@/components/v2/list/content.vue'
import { toast } from '@/toasts'
import loading from '@/components/v2/loading.vue'
import container from '@/components/v2/container.vue'
import tabList from '@/components/v2/tabs/tab_list.vue'
import tab from '@/components/v2/tabs/tab.vue'
import pagination from '@/components/v2/pagination/pagination.vue'
import btn from '@/components/v2/btn.vue'
import modal from '@/components/v2/modal/modal.vue'
import modalHeader from '@/components/v2/modal/header.vue'
import modalActions from '@/components/v2/modal/actions.vue'
import modalContent from '@/components/v2/modal/content.vue'
import gForm from '@/components/v2/form/form.vue'
import formField from '@/components/v2/form/field.vue'
import formFieldGroup from '@/components/v2/form/field_group.vue'
import formInput from '@/components/v2/form/input.vue'
import formBtn from '@/components/v2/form/btn.vue'
import formLabel from '@/components/v2/form/label.vue'
import formValidation from '@/components/v2/form/validation.vue'
import defaultUserAvatar from '@/assets/img/profile_avatar_small.png'
import grid from '@/components/v2/grid/grid.vue'
import gridColumn from '@/components/v2/grid/column.vue'
import placeholder from '@/components/v2/placeholder.vue'
import pageHeader from '@/components/v2/page_header.vue'

import dateMixin from '@/mixins/v2/dateMixin'

export default {
  components: {
    list,
    listItem,
    listContent,
    loading,
    container,
    tabList,
    tab,
    pagination,
    btn,
    modal,
    modalActions,
    modalContent,
    modalHeader,
    gForm,
    formField,
    formFieldGroup,
    formInput,
    formBtn,
    formValidation,
    formLabel,
    grid,
    gridColumn,
    placeholder,
    pageHeader,
  },
  mixins: [dateMixin],
  data() {
    return {
      parseISO,
      defaultUserAvatar,
      users: [],
      usersPagination: null,
      pageLoading: false,
      formLoading: false,
      newUser: {
        preferredFirstName: '',
        lastName: '',
        email: '',
        personalEmail: '',
      },
      isFormValid: false,
    }
  },
  computed: {
    placeholderText() {
      if (this.$route.hash === '#onboarding') {
        return "No one's onboarding now"
      }

      if (this.$route.hash === '#inactive') {
        return 'No alumni yet'
      }

      if (this.$route.hash === '#active') {
        return 'No active employees'
      }

      throw new TypeError(`Got unexpected hash ${this.$route.hash}`)
    },
  },
  watch: {
    $route: {
      handler() {
        this.getPage()
      },
    },
  },
  created() {
    if (!this.$route.hash) {
      this.$router.replace({ hash: '#active' })
    }
  },
  mounted() {
    this.getPage()
  },
  methods: {
    async getPage() {
      try {
        this.pageLoading = true
        await this.getUsers()
      } catch (error) {
        toast.error(error)
      } finally {
        this.pageLoading = false
      }
    },
    reset() {
      this.newUser = {
        preferredFirstName: '',
        lastName: '',
        email: '',
        personalEmail: '',
      }
    },

    async getUsers() {
      try {
        const sort = {
          '#onboarding': 'startDate,preferredFirstName,lastName,id',
          '#active': 'preferredFirstName,lastName,id',
          '#inactive': 'endDate-desc,preferredFirstName,lastName,id',
        }
        const response = await api.get(
          `${process.env.VUE_APP_DB_ENDPOINT}/v2/users`,
          {
            page: parseInt(this.$route.query.page || 1, 10),
            status: this.$route.hash.slice(1),
            sort: sort[this.$route.hash],
          }
        )
        this.users = response.data
        this.usersPagination = response.cursor
      } catch (error) {
        toast.error(error)
      }
    },
    async createUser(submission) {
      try {
        this.formLoading = true
        const result = await api.post(
          `${process.env.VUE_APP_DB_ENDPOINT}/v2/users`,
          {
            preferredFirstName: submission.get('First Name'),
            lastName: submission.get('Last Name'),
            //  our backend requires emails to be lowercased
            email: toLower(submission.get('Work Email')) || null,
            personalEmail: toLower(submission.get('Personal Email')) || null,
            organizationId: this.$store.state.organizationId,
            status: 'active',
          }
        )
        this.$router.push({
          name: 'add_person',
          params: {
            id: result.data.id,
          },
        })
      } catch (error) {
        toast.error(error)
      } finally {
        this.formLoading = false
      }
    },
  },
}
</script>

<style lang="less" scoped>
.btn {
  margin: 0 0 0.5em 1em;
}

.formatted-date {
  margin-top: 0.35em !important;
}
</style>
